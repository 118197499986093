<template>
  <div class="location" :class="[siteTag, {stuck: stuck}]">
    <!-- MODAL BUTTON -->
    <button
      v-if="!hasDropdown"
      class="location-button"
      :class="siteTag"
      data-toggle="modal"
      data-target="#shipping-address-modal"
    >
      <div class="button-body">
        <i class="fa fa-fw fa-map-marker-alt fa-xl"></i>
        <div class="shipping-label" v-html="shippingLabel"></div>
        <span
          class="glyphicon glyphicon-menu-right"
          style="color: black"
        ></span>
      </div>
    </button>

    <!-- DROPDOWN BUTTON -->
    <button
      class="location-button"
      :class="[siteTag, {stuck: stuck}]"
      @mouseenter="$emit('menu-hovered')"
      @mouseleave="$emit('menu-hovered')"
      v-else
    >
      <div class="button-body">
        <div class="location-label">
          <i class="fa fa-fw fa-map-marker-alt fa-xl"></i>
          <div class="shipping-label" v-html="shippingLabel"></div>
        </div>

        <span class="glyphicon glyphicon-menu-right"></span>
      </div>

      <div
        class="menu-body"
        :class="{ 'forced-open': locationFocused }"
        v-if="hasDropdown"
      >
        <div class="menu-body-content">
          <p>Providing your location will help us ensure you get the most accurate
          shipping time and rate.</p>
          <input
            type="text"
            class="form-control input-sm shipping-address-us-zip-input-vue"
            style="margin: 4px 0"
            placeholder="Zip code"
            @focus="locationFocused = true"
            @blur="locationFocused = false"
          />
          <button
            class="btn btn-default btn-block btn-sm apply-shipping-address-button-vue"
          >
            <i class="fa fa-fw fa-map-marker-alt"></i> Set Location
          </button>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "location-button",

  props: {
    siteTag: { type: String, defualt: "pz" },
    stuck: {type: Boolean},
    shippingLocation: {
      default: () => {},
    },
    hasDropdown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      locationFocused: false,
    };
  },

  computed: {
    shippingLabel() {
      if (this.shippingLocation) {
        if (parseInt(this.shippingLocation.country_id) === 198) {
          return "<strong>" + this.shippingLocation.zip + "</strong>";
        }

        return "<p><span>Deliver From</span><br/>Main Warehouse</p>";
      }

      return "<strong>Location</strong>";
    },
  },
};
</script>

<style lang="scss">
// for mobile menu
.utils {
  .location {
    .location-button {
      padding: 0;
      .button-body {
        padding: 0 !important;
        .fa {
          width: 100%;
        }
        .shipping-label {
          width: 100%;
        }
        .glyphicon {
          width: 100%;
        }
      }
      &:hover {
        background: none !important;
        color: rgba(195, 22, 28, 1) !important;
      }
    }
  }
}

div.location {
  display: flex;
  margin-left: auto;
  place-self: stretch end;
  text-align: center;
  flex: 1 0 auto;
  .location-button {
	align-self: stretch;
	align-items: center;
    background: transparent;
	border: 0;
    border-radius: 3px;
	display: flex;
    padding: 0.5rem 0;
    position: relative;
    &.bn {
      color: #222;
      background: #f3f3f3;
      &:hover {
        color: lighten(#222, 15%);
        background-color: #f3f3f3;
      }
      .location-label {
        .shipping-label {
          p {
            color: #3777bc;
            &:hover {
              color: darken(#3777bc, 15%);
            }
          }
        }
      }
      .menu-body {
        background: #f3f3f3;
        color: #000;
      }
    }
    &.pz {
      background: #000;
      color: #fff;
      .menu-body {
        background: #222;
        color: #fff;
      }
      &.stuck {
        background: #222;
      }
      .menu-body > .menu-body-content > p {
        color: #fff;
      }
    }
	&.fd {
      background: #000;
      color: #fff;
	  &:hover {
		background: #222;
	  }
      .menu-body {
        background: #222;
        color: #fff;
      }
      &.stuck {
        background: #000;
		&:hover {
			background: #222;
		}
      }
      .menu-body > .menu-body-content > p {
        color: #fff;
      }
    }
    &.pz:hover {
      background: #222;
    }
    div.button-body {
      border: none;
      outline: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      padding: 6px 12px;
      font-size: 1em;
      line-height: 1;
      i {
        margin-right: 0.5rem;
      }
      span {
        margin-left: 1rem;
      }
      .location-label {
        display: flex;
        align-items: center;
          .shipping-label {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            & p {
              margin: 0;
              color: inherit;
              & span {
                margin: 0;
                font-weight: 900;
              }
            }
          }
      }
    }

    &:hover {
      background-color: #000;
      color: white;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 99;
      div.menu-body {
        transition: all 100ms ease-in-out;
        opacity: 1;
        transform: translateY(14rem);
      }
      &.stuck {
        &:hover {
          div.menu-body {
            transform: translateY(13.5rem);
          }
        }
      }
    }

    div.menu-body {
      color: white;
      position: absolute;
      right: 0;
      transition: all 100ms ease-in-out;
      opacity: 0;
      transform: translateY(-10px) scaleY(0);
      transform-origin: top center;
      width: 240px;
      border-radius: 5px;
      border-top-right-radius: 0;
      text-align: left;
      padding: 1rem;
      .menu-body-content {
        padding: 1rem;
        p {
          color: black;
        }
      }
      &.forced-open {
        opacity: 1;
        transform: translateY(14rem) scaleY(1);
      }
    }
  }
}

@media (max-width: 640px) {
  div.location {
    margin-left: 0;
    min-width: 110px;
  }
}
</style>
