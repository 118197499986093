export default {
	data() {
		return {
			isMobile: window.matchMedia('(max-width: 768px)').matches,
			mediaQueryList: window.matchMedia('(max-width: 768px)'),
		};
	},
	mounted() {
		this.mediaQueryList.addEventListener('change', this.checkMobile);
	},
	beforeDestroy() {
		this.mediaQueryList.removeEventListener('change', this.checkMobile);
	},
	methods: {
		checkMobile(event) {
			this.isMobile = event.matches;
		},
	}
}