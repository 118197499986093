<template>
	<div class="menu-list">
		<div v-if="loading || loadingURL">
			<div
				v-for="i in 24"
				class="loading-bar"
			></div>
		</div>
		<template
			v-else
			v-for="(item, index) in filteredItems"
		>
			<ul
				v-if="Object.entries(item).length > 1"
				class="list-items list-submenu"
				:key="index"
				ref="menu"
			>
				<li
					v-if="item.name_key && !loading"
					class="list-buttons flexer"
				>
					<button
						class="btn btn-link back-btn"
						@click.prevent="trackRevertSubMenu(item.name_key)"
					>
						<i class="fa fa-w fa-arrow-left"></i>&nbsp;{{ backText(item) }}
					</button>
					<button
						v-if="isFilterView && !filterView"
						class="btn btn-link start-over-btn"
						@click.prevent="backToFilterView()"
					>
						<i class="fa fa-w fa-bars"></i>&nbsp;View Filters
					</button>
					<button
						v-else-if="!isFilterView"
						class="btn btn-link start-over-btn"
						@click.prevent="restartMenu()"
					>
						<i class="fa fa-w fa-undo"></i>&nbsp;Restart
					</button>
				</li>
				<li
					v-else-if="isFilterView && !filterView && index === 0 && item.items"
					class="list-buttons flexer"
				>
					<button
						class="btn btn-link start-over-btn"
						@click.prevent="backToFilterView()"
					>
						<i class="fa fa-w fa-bars"></i>&nbsp;View Filters
					</button>
				</li>
				<li
					v-else-if="isFilterView && index === 0 && item.items"
					class="list-buttons flexer"
				>
					<button
						class="btn btn-link start-over-btn"
						@click.prevent="viewNavigation()"
					>
						<i class="fa fa-w fa-arrow-left"></i>&nbsp;Navigation
					</button>
				</li>
				<template v-if="!filterView">
					<li
						v-if="item.title"
						:key="filteredItems.indexOf(item)"
						class="list-title"
					>
						<span v-if="item.items && !Object.keys(item.items).length"> Choose a Category </span>
						<span
							v-else
							v-html="item.title"
						/>
					</li>
					<li
						v-if="errorMessage"
						class="not-found"
					>
						{{ errorMessage }}
					</li>
					<li
						v-else-if="item.items && !Object.keys(item.items).length"
						class="not-found"
					>
						No products found
					</li>
					<template v-else>
						<li v-if="item.items && item.items.length > 10 && !item.hardcode">
							<div class="search">
								<div>
									<input
										:value="searchFilter"
										@input="filterSearch($event, item.name_key)"
										placeholder="Filter"
										type="text"
									/>
								</div>
								<span
									v-if="searchFilter.length"
									class="fa fa-times"
									@click="searchFilter = ''"
								/>
								<span
									v-else
									class="fa fa-search"
								></span>
							</div>
						</li>
						<li
							v-for="(entry, key) in filtered(item.items)"
							v-if="entry.label || entry.url"
							:key="key + '_list_item'"
							class="list-item"
							:class="{
								'list-title': entry.bold,
								striped: item.items.length > 10,
								'active-list-item': isActive(entry.name_key),
							}"
							:title="entry.label"
						>
							<a
								v-if="entry.url"
								:href="entry.url"
								v-html="convertCaseAsTitle(entry.label, item.hardcode)"
								@click.prevent="clickLink(entry)"
							/>
							<span
								v-else-if="entry.bold"
								v-html="entry.label"
							/>
							<button
								v-else
								class="btn-link"
								@click.stop="clickShowSubMenu(item, entry)"
							>
								<span v-html="entry.label" />
							</button>
						</li>
						<li
							v-if="searchFilter && !filtered(item.items).length"
							class="not-found"
						>
							No Results
						</li>
					</template>
				</template>
			</ul>
		</template>
		<template v-if="filterView">
			<filter-view
				:filterItems="filterItems"
				:siteTag="siteTag"
				:garage-enabled="garageEnabled"
			/>
		</template>
	</div>
</template>

<script>
import Data from '@/mixins/data';
import Tracking from '@/mixins/event-tracking';
import _ from 'lodash';

// https://stackoverflow.com/questions/8085004/iterate-through-nested-javascript-objects
function locateByNameKey(O, f, cur) {
	O = [O];
	while (O.length) {
		if (!f((cur = O.pop())) && cur instanceof Object && [Object, Array].includes(cur.constructor)) {
			O.push.apply(O, Object.values(cur));
		}
	}
}

export default {
	name: 'menu-list',
	mixins: [Data, Tracking],
	props: {
		items: { type: Array, default: () => [] },
		title: String,
		filterItemsEncoded: {
			type: String,
			default: '',
		},
		siteTag: String,
		menuToOpen: String,
		garageEnabled: { type: Boolean, default: false },
		initialSideMenuEncoded: {
			type: String,
			default: '',
		},
		initialParentMenuEncoded: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			filteredItems: this.items,
			menu: this.items,
			loading: false,
			loadingURL: false,
			errorMessage: '',
			searchFilter: '',
			filterView: false,
			filterItems: null,
			breadcrumbs: [],
			navigationInProgress: false,
		};
	},
	computed: {
		isFilterView() {
			return this.filterItems !== null;
		},
		initialSideMenu() {
			return this.parseBase64(this.initialSideMenuEncoded) ?? {};
		},
		initialParentMenu() {
			return this.parseBase64(this.initialParentMenuEncoded) ?? {};
		},
	},
	methods: {
		clickLink(entry) {
			this.navigationInProgress = false;
			this.trackEvent('sidebarClickURL', {
				eventCategory: 'Sidebar Navigation',
				eventLabel: 'Click Link',
				url: entry.url,
				itemLabel: entry.label,
			});
			window.location.href = this.addParams(entry.url);
		},
		filterSearch(e, name_key) {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.trackEvent(
					'sidebarMenuFilter',
					{
						eventCategory: 'Sidebar Navigation',
						eventLabel: 'Menu Filter',
						doc_key: name_key,
						searchTerm: e.target.value,
					},
					false
				);
				this.searchFilter = e.target.value;
			}, 300);
		},
		trackRevertSubMenu(name_key) {
			this.trackEvent('sidebarClickBack', {
				eventCategory: 'Sidebar Navigation',
				eventLabel: 'Click Back',
				docKey: name_key,
				itemLabel: 'Back',
			});
			this.revertSubMenu(name_key);
		},
		clickShowSubMenu(item, entry) {
			this.navigationInProgress = true;
			this.trackEvent('sidebarClickItem', {
				eventCategory: 'Sidebar Navigation',
				eventLabel: 'Click Item',
				docKey: entry.name_key,
				itemLabel: entry.label,
			});
			this.showSubMenu(item, entry, true);
		},
		viewNavigation() {
			this.trackEvent('sidebarViewNavigation', {
				eventCategory: 'Sidebar Navigation',
				eventLabel: 'Click View Navigation',
			});
			this.restartMenu(true);
		},
		isActive(name_key) {
			return window.location.pathname.substring(1) === name_key;
		},
		convertCaseAsTitle(str, hardcoded = false) {
			if (hardcoded) {
				return str;
			}
			return str.replace(/\S+/g, function (word) {
				return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
			});
		},
		backText(item) {
			let name_key = item.name_key.split('/');
			name_key.pop();
			name_key = name_key.join('/');
			if (name_key === '') {
				return 'Home';
			}
			let index = this.breadcrumbs.findIndex((breadcrumb) => {
				return breadcrumb.doc_key === name_key;
			});
			if (index > -1 && item.doc_name) {
				return item.doc_name;
			}
			return 'Back';
		},
		backToFilterView() {
			this.filterView = true;
			try {
				this.initMenu(window.location.pathname);
			} catch (error) {
				this.restartMenu();
			}
			this.$emit('showingSubMenu', true);
		},
		/**
		 * Filter a set of menu items based on the search filter.
		 * @param {Object} items Menu items
		 */
		filtered(items) {
			if (!items) return [];
			return Object.keys(items)
				.map((key) => items[key])
				.filter((item) => {
					// @todo - shouldn't have to hardcode this kind of thing
					if (item.label == 'Show All Brands' && location.pathname == '/catalog') {
						return false;
					}
					if (item.label && (!item.hide || location.pathname == '/catalog')) {
						return item.label.toLowerCase().includes(this.searchFilter.toLowerCase());
					}
					return false;
				});
		},
		/**
		 * Fetch the menu items from the server.
		 * @param {String} pathString
		 */
		getMenuItems(pathString) {
			return new Promise((resolve, reject) => {
				this.loading = true;
				fetch(`/catalog/api/${pathString}`)
					.then((res) => {
						if (!res.ok) {
							reject(res.status);
						}
						return res.json();
					})
					.then((data) => {
						resolve(data);
					})
					.catch((err) => {
						reject(err);
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		/**
		 * Construct a dummy menu structure on page load to allow
		 * for URL navigation.
		 * @param {String} pathString
		 */
		initMenu(pathString, isInit = false) {
			// Create an array from the url
			if (pathString.startsWith('/')) {
				pathString = pathString.slice(1);
			}
			let path = pathString.split('/');
			let newPath = [path.shift()];
			while (path.length > 0) {
				newPath.push(newPath[newPath.length - 1] + '/' + path.shift());
			}
			newPath.shift();

			// Create the menu structure from the array
			let foundObject = null;
			let currentObj = this.menu;
			let current;
			let lastObj = currentObj;
			let newItem = null;
			for (let i = 0; i < newPath.length; i++) {
				foundObject = null;
				current = newPath[i];

				// Try to find a node in the menu with the same name_key
				locateByNameKey(currentObj, function (currentValue) {
					if (currentValue && currentValue.constructor === Object && currentValue.name_key === current) {
						foundObject = currentValue;
					}
				});

				if (foundObject !== null) {
					currentObj = foundObject;
					if (i === newPath.length - 1) {
						newItem = {
							name_key: current,
							title: 'Loading...',
							items: [],
						};
						currentObj.items = Object.keys(currentObj.items).map((key) => currentObj.items[key]);
						currentObj.items[currentObj.items.length] = newItem;
						lastObj = currentObj;
					}
				} else {
					newItem = {
						name_key: current,
						title: 'Loading...',
						items: [],
					};
					currentObj.items = Object.keys(currentObj.items).map((key) => currentObj.items[key]);
					currentObj.items[currentObj.items.length] = newItem;
					lastObj = currentObj;
					currentObj = currentObj.items[currentObj.items.length - 1];
				}
			}

			// Menu options are fetched from the server when showSubMenu finds no children
			this.showSubMenu(lastObj, lastObj.items.length - 1, false, isInit);
		},
		processMenuItems(items, pathString) {
			this.breadcrumbs = items.breadcrumb
				? [
						{
							name: 'Home',
							doc_key: '',
						},
						{
							name: 'Powersports',
							doc_key: 'catalog',
						},
						...items.breadcrumb,
						{
							name: items.doc_name,
							doc_key: items.name_key,
						},
				  ]
				: [
						{
							name: 'Home',
							doc_key: '',
						},
						{
							name: 'Powersports',
							doc_key: 'catalog',
						},
						{
							name: items.doc_name,
							doc_key: items.name_key,
						},
				  ];
			let foundObject = null;
			// This is a category, not a component. It should have a submenu
			if (items.items && items.items.length) {
				if (items.items.length > 1) {
					items.items = items.items
					.sort((a, b) => b.items.length - a.items.length)
					.map((item, index) => {
						let newItem = {
							...items.items[index],
							items: [...items.items[index].items],
						};
						if (items.items[index + 1]) {
							newItem.items = [
								...newItem.items,
								{
									label: items.items[index + 1].title,
									bold: true,
								},
								...items.items[index + 1].items,
							];
						}
						return newItem;
					});
				}
				if (items.items[0].type !== 'component') {
					items.items[0].items.forEach((item) => {
						if (!item.has_products) {
							item.potential_url = item.url;
							delete item.url;
							if (!item.bold) {
								item.items = [];
							}
						}
					});
				}
				const path = items.items[0].name_key;
				locateByNameKey(this.menu, function (currentValue) {
					if (currentValue && currentValue.constructor === Object && currentValue.name_key === path) {
						foundObject = currentValue;
					}
				});
				if (foundObject !== null) {
					foundObject.items = items.items[0].items;
					foundObject.title = items.items[0].title;
					foundObject.doc_name = items.doc_name;

					// if there are group names within the items, we should prepend group labels
					const hasGroupName = foundObject.items.some((entry) => entry.group_name !== false);
					if (hasGroupName) {
						const sortedItems = foundObject.items.toSorted((a, b) =>
							a.group_name && b.group_name ? a.group_name.localeCompare(b.group_name) : 0
						);
						const groupNames = this.getGroupNames(sortedItems);
						const firstUniqueIndexes = this.getFirstIndexForGroupNames(sortedItems, groupNames);
						foundObject.items = this.prependGroupLabels(sortedItems, firstUniqueIndexes);
					}
				}
				return true;
			}

			// If it's a component page, revert to the parent menu
			if (items.type === 'component' || items.type === 'automotive') {
				this.revertSubMenu(pathString);
				return false;
			}

			// If it's a product page, find the parent menu and redirect to its URL
			if (items.has_products) {
				locateByNameKey(this.menu, function (currentValue) {
					if (currentValue && currentValue.constructor === Object && currentValue.name_key === pathString) {
						foundObject = currentValue;
					}
				});
				if (foundObject !== null && foundObject.potential_url) {
					this.loadingURL = true;
					this.navigationInProgress = false;
					this.trackEvent('sidebarClickURL', {
						eventCategory: 'Sidebar Navigation',
						eventLabel: 'Click Link',
						url: foundObject.potential_url,
						itemLabel: foundObject.label,
					});
					window.location.href = this.addParams(foundObject.potential_url);
				}
			}
			return true;
		},
		/**
		 * Update the contents of the menu with new items
		 * from the server.
		 * @param {String} pathString
		 */
		updateMenu(pathString, isInit = false) {
			if (isInit && Object.keys(this.initialSideMenu).length) {
				return this.processMenuItems(this.initialSideMenu, pathString);
			} else {
				return this.getMenuItems(pathString.replace(/catalog\/?/, ''))
					.then((items) => {
						return this.processMenuItems(items, pathString);
					})
					.catch((err) => {
						if (err === 404) {
							try {
								// If the page isn't found, revert to the parent menu
								this.revertSubMenu(pathString);
							} catch (error) {
								this.restartMenu();
							}
							return false;
						}
						this.errorMessage = 'Error fetching menu items. Please try again.';
					});
			}
		},
		/**
		 * Look for the matching name_key & update the
		 * filteredItems variable with the parent array of the matching title.
		 * @param {String} title
		 * @param {Boolean} preservePath Is this reverting to name_key or to name_key's parent?
		 */
		revertSubMenu(name_key, preservePath = false) {
			this.errorMessage = '';
			this.searchFilter = '';
			if (this.filterItemsEncoded) {
				this.filterView = false;
			}
			let path = name_key.split('/');
			if (!preservePath) {
				path.pop();
			}
			path = path.join('/');
			let foundObject = null;

			locateByNameKey(this.items, function (currentValue) {
				if (currentValue && currentValue.constructor === Object && currentValue.name_key === path) {
					foundObject = currentValue;
				}
			});

			if (foundObject !== null) {
				this.filteredItems = [foundObject];
				// The parent array most likely has a fake item that was
				// generated to support the tree, so fetch from server.
				if (Object.keys(foundObject.items).length < 2) {
					let newerPath = foundObject.name_key.split('/');
					newerPath.pop();
					newerPath = newerPath.join('/');
					if (this.initialParentMenu && this.initialParentMenu.name_key === newerPath) {
						this.processMenuItems(this.initialParentMenu, foundObject.name_key);
					} else {
						this.updateMenu(foundObject.name_key);
					}
				}
			} else {
				/* Check if it's a powersports item (need to hardcode this because of
				artificial powersports/automotive menus) */
				if (name_key.split('/').length > 1 && !/automotive|aftermarket/.test(name_key)) {
					locateByNameKey(this.items, function (currentValue) {
						if (
							currentValue &&
							currentValue.constructor === Object &&
							currentValue.name_key === 'powersports'
						) {
							foundObject = currentValue;
						}
					});
					if (foundObject !== null) {
						this.filteredItems = [foundObject];
						return;
					}
				}
				// Must be the top level menu
				this.$emit('showingSubMenu', false);
				this.filteredItems = this.items;
			}
		},
		/**
		 * Drill down into the next set of items to show the submenu of that array.
		 * @param {Array<Object>} item
		 * @param {Number|Object} index The index of the subitem to show or a child of the item itself
		 * @param {Boolean} isClickEvent was this called by a click event?
		 * @param {Boolean} isInit Indicates whether the menu is being built by a ref from the URL, assigns loadingURL to false
		 */
		async showSubMenu(item, subItem, isClickEvent = false, isInit = false) {
			let index = subItem;
			if (typeof subItem === 'object') {
				index = Object.keys(item.items).findIndex((i) => item.items[i].name_key === subItem.name_key);
			}
			if (index === -1) {
				return;
			}
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
			this.errorMessage = '';
			this.searchFilter = '';
			const items = item.items[index];
			let proceed = true;
			// the hardcode property is needed because a name key is necessary for back navigation,
			// but we can't hardcode name_keys to ignore in the code.
			if (items.name_key && !items.hardcode) {
				proceed = await this.updateMenu(items.name_key, isInit);
			}
			this.$emit('showingSubMenu', true);
			if (proceed) {
				const newFiltered = Object.keys(item.items).map(key => item.items[key])[index];
				this.filteredItems = [newFiltered];
				let hasItems = this.filteredItems.find((item) => item.items);
				if (isClickEvent && hasItems && hasItems.items.length === 1) {
					if (hasItems.items[0].url) {
						this.loadingURL = true;
						location.href = this.addParams(hasItems.items[0].url);
						return;
					}
					this.showSubMenu(hasItems, 0, true);
				}
			}
		},
		addParams(url) {
			// if use_fitment is in the window location, add it to the url
			if (window.location.search.includes('use_fitment')) {
				const urlObj = new URL(window.location.origin + url);
				urlObj.searchParams.set('use_fitment', '1');
				return urlObj.toString();
			}
			return url;
		},
		/**
		 * Revert the menu back to it's original state with one click of a button.
		 * @param {Boolean} hideFilterView Should the filter view be shown?
		 */
		restartMenu(hideFilterView = false) {
			if (hideFilterView) {
				this.filterView = false;
			}
			this.errorMessage = '';
			this.searchFilter = '';
			this.$emit('showingSubMenu', false);
			this.filteredItems = this.items;
			if (window.innerWidth < 992) {
				// wait for the next DOM update cycle
				this.$nextTick(() => {
					// then scroll to the top
					const mainMenu = this.$refs.menu.find((menu) => {
						return (
							menu.firstElementChild &&
							menu.firstElementChild.innerText.toLowerCase() == 'shop replacement parts'
						);
					});
					if (mainMenu) {
						mainMenu.scrollIntoView({
							block: 'start',
							behavior: 'smooth',
						});
					}
				});
			}
		},
		resultsFetchedHandler(e) {
			this.filterItems = _.get(e, ['detail', 'results', 'data'], {});
		},
		toggleMenuHandler(e) {
			if (this.isFilterView) {
				this.filterView = true;
				this.$emit('showingSubMenu', true);
			}
		},
		getGroupNames(items) {
			const groupNames = items.reduce((acc, item) => {
				if (item.group_name !== false && item.group_name) {
					if (!acc.includes(item.group_name)) {
						acc.push(item.group_name);
					}
				}
				return acc;
			}, []);
			return groupNames;
		},
		getFirstIndexForGroupNames(items, groupNames) {
			const indexMap = {};
			groupNames.forEach((groupName) => {
				const index = items.findIndex((item) => item.group_name === groupName);
				if (index !== -1) {
					indexMap[groupName] = index;
				}
			});
			return indexMap;
		},
		prependGroupLabels(items, indexMap) {
			const updatedItems = [...items];
			const groupNames = Object.keys(indexMap).sort((a, b) => indexMap[a] - indexMap[b]);
			for (let i = 0; i < groupNames.length; i++) {
				const groupName = groupNames[i];
				const index = indexMap[groupName] + i;
				const groupLabel = {
					label: groupName,
					bold: true,
				};
				updatedItems.splice(index, 0, groupLabel);
			}
			return updatedItems;
		},
		destroyMyself() {
			this.$destroy();
		},
	},
	beforeMount() {
		this.filterItems = {};
		if (this.filterItemsEncoded) {
			this.filterItems = this.parseBase64(this.filterItemsEncoded);
		}
	},
	mounted() {
		let path = window.location.pathname;
		let url = new URL(window.location.href);
		let ref = url.searchParams.get('ref');
		this.menu = this.items;
		if (ref && _.get(this.initialSideMenu, ['items', 0, 'name_key'], null)) {
			path = this.initialSideMenu.items[0].name_key;
		}
		try {
			this.initMenu(path, true);
		} catch (error) {
			this.restartMenu();
		}
		if (this.isFilterView) {
			this.$emit('showingSubMenu', true);
			this.filterView = true;
		}

		window.addEventListener('resultsFetched', this.resultsFetchedHandler);
		window.addEventListener('toggleMenu', this.toggleMenuHandler);
		window.addEventListener('beforeunload', this.destroyMyself);
	},
	watch: {
		// Opens to powersports/automotive sections from the header menu
		menuToOpen(val) {
			let index = Object.keys(this.menu[0].items).findIndex((key) => this.menu[0].items[key].label === val);
			if (index > -1) {
				this.filterView = false;
				this.showSubMenu(this.menu[0], index);
			}
		},
		filterView(val) {
			this.$emit('showingFilters', val);
		},
	},
	components: {
		FilterView: () => import('@/components/header/FilterView.vue'),
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.destroyMyself);
		if (this.navigationInProgress) {
			this.trackEvent('sidebarAbandonment', {
				eventCategory: 'Sidebar Navigation',
				eventLabel: 'Sidebar Abandonment',
				currentPage: window.location.pathname,
			});
		}
		window.removeEventListener('resultsFetched', this.resultsFetchedHandler);
		window.removeEventListener('toggleMenu', this.toggleMenuHandler);
	},
};
</script>
<style lang="scss" scoped>
.search {
	position: relative;
	margin: 0 0 10px 0.8em;
	color: initial;
	div {
		width: 100%;
		border: 1px solid;
		border-color: color(display-p3 0.862745 0.862745 0.862745 / 1);
		border-color: #dcdcdc;
		input {
			padding: 5px;
			width: 90%;
			border: none;
			&:focus-visible {
				outline: none;
			}
		}
	}
	.fa {
		position: absolute;
		top: 8px;
		left: 90%;
	}
	.fa-times {
		cursor: pointer;
		font-size: 1.2em;
		top: 8px;
		&:hover {
			color: #c3161c;
			color: color(display-p3 0.764706 0.086275 0.109804 / 1);
		}
	}
}
.btn-link:focus {
	outline: none;
}
.back-btn {
	max-width: 50%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block !important;
}
.list-items li:not(.list-buttons) {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.active-list-item a {
	padding-left: 25px;
	border-left: 5px solid;
	display: block;
	position: relative;
}

.striped:nth-of-type(odd) {
	background-color: color(display-p3 0.956863 0.956863 0.956863 / 1);
	background-color: #f4f4f4;
}

.not-found {
	font-size: 1.5rem;
	font-weight: 600;
	padding: 0.5em 1.5em;
	width: 100%;
	span {
		margin-right: calc(10 / var(--default-font-size) * 1rem);
	}
}
.loading-bar {
	background: #fff;
	width: 100%;
	height: 30px;
	&:nth-of-type(odd) {
		animation: loading-pulse 1000ms linear infinite;
		background-color: color(display-p3 0.956863 0.956863 0.956863 / 1);
		background-color: #f4f4f4;
	}
}

@keyframes loading-pulse {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
</style>
