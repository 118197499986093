export default {
	created() {
		window.addEventListener('track-ga4-event', this.handleCustomEvent);
	},
	beforeDestroy() {
		window.removeEventListener('track-ga4-event', this.handleCustomEvent);
	},
	methods: {
		trackEvent(event, data, persist = true) {
			if (!persist) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ ecommerce: null });
			}
			if (window.dataLayer) {
				window.dataLayer.push({
					event: event,
					...data,
				});
			} else {
				console.warn('dataLayer is not defined');
			}
		},
		generateEcommerceData(customData = {}) {
			if (this.product && this.product !== null && Object.entries(this.product).length) {
				return {
					currency: 'USD',
					value: this.product.price || customData.price,
					items: [
						{
							item_id: this.product.sku || customData.sku,
							item_name: this.productName || customData.product_name,
							price: this.product.price || customData.price,
							quantity: this.qty || customData.quantity,
							item_brand: this.manufacturer || customData.manufacturer,
							item_category: this.productType || customData.category,
							...customData,
						},
					],
				};
			}
			return null;
		},
		handleCustomEvent(event) {
			const { detail } = event;
			this.trackEvent(detail.event, { ...detail.data, ecommerce: this.generateEcommerceData() }, false);
		},
	},
};
